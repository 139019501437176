
import { defineComponent } from 'vue';
import HackatonForest from './components/HackatonForest.vue';

export default defineComponent({
  name: 'App',
  components: {
    HackatonForest
}
});


import { defineComponent } from 'vue';
import { HackatonScene } from '@/Babylon/HackatonScene';

export default defineComponent({
  name: 'HackatonForest',
  mounted(){
    const canvas = document.querySelector('canvas')!;
    new HackatonScene(canvas); 
  }
});

import { tSConstructorType } from "@babel/types";
import {Scene, Engine, FreeCamera, HemisphericLight, MeshBuilder, Vector3, StandardMaterial, Texture, SceneLoader, Mesh, CubeTexture, Color3, DynamicTexture, Sound, Material} from "@babylonjs/core"
import "@babylonjs/loaders"
import axios, { AxiosResponse } from "axios"
type User = {
    clickWrap: boolean,
    greenScore: string,
    greenScoreLevel: string,
    idMynamirial: string,
    partnerId: string,
    username: string
}

type GetLeaderboardResponse = {
    Response: User[];
}

export class HackatonScene {
    
    scene:Scene;
    engine:Engine;

    constructor(private canvas:HTMLCanvasElement){
        this.engine = new Engine(this.canvas, true)
        this.engine.displayLoadingUI();
        this.scene = this.CreateScene();
        this.scene.collisionsEnabled = true;

        this.engine.runRenderLoop(()=>{
            this.scene.render();            
        })

    }



    CreateScene():Scene{
        const scene = new Scene(this.engine)
        //creo camera e controller
        this.createController();

         
        const hemiLight = new HemisphericLight("hemiLight", new Vector3(0,1,0), this.scene)
        hemiLight.intensity = 1;
        const ground = MeshBuilder.CreateGround("ground", {width:1000, height:1000}, this.scene)
        ground.material = this.CreateGroundMaterial();
        ground.checkCollisions = true;


        this.createSkybox()
        
        //blocco la telecamera su me stesso
        scene.onPointerDown = (evt)=>{
            if(evt.button === 0) this.engine.enterPointerlock();
            if(evt.button === 1) this.engine.exitPointerlock();
        }

        const framePerSecond = 60;
        const gravity = -9.81;
        scene.gravity = new Vector3(0, gravity/framePerSecond, 0),
        scene.collisionsEnabled = true;
        
        let trees = 0
        const co2 = 20000
        this.GetEcoScores().then(risposta => {
            console.log(risposta)
            for (let i = 0; i < risposta.length; i++) {
                const utente = risposta[i] as User
                trees = trees + Number(utente.greenScoreLevel)
            }
            this.CreateTrees(trees);
            this.createCounterBox(trees, co2);
            this.engine.hideLoadingUI();
        })
        this.createMusic()
        return scene;
    }

    createController():void{
        const camera = new FreeCamera("camera", new Vector3(0,2,-15), this.scene)
        camera.attachControl()

        camera.speed = 0.25;

        camera.applyGravity = true;
        camera.checkCollisions = true;

        //anche la telecamera deve avere una dimensione se deve collidere con qualcosa
        camera.ellipsoid = new Vector3(1,1,1)

        //attivo anche i tasti wasd
        camera.keysUp.push(87);
        camera.keysLeft.push(65);
        camera.keysDown.push(83);
        camera.keysRight.push(68);

    }

    createCounterBox(howManyTrees=10, howManyCO2=2000):void{

    //Set font type
    const font_type = "Arial";
	
	//Set width an height for plane
    const planeWidth = 10;
    const planeHeight = 1;

    //Create plane
    const plane = MeshBuilder.CreatePlane("plane", {width:planeWidth, height:planeHeight}, this.scene);
    plane.visibility = 0.3;
    plane.position.y = 1;
    plane.position.z = -8;

    //Set width and height for dynamic texture using same multiplier
    const DTWidth = planeWidth * 60;
    const DTHeight = planeHeight * 60;

    //Set text
    const text = `With you, Namirial planted ${howManyTrees} trees and saved ${howManyCO2} tons of CO2`;
    
    //Create dynamic texture
    const dynamicTexture = new DynamicTexture("DynamicTexture", {width:DTWidth, height:DTHeight}, this.scene);

    //Check width of text for given font type at any size of font
    const ctx = dynamicTexture.getContext();
	const size = 12; //any value will work
    ctx.font = size + "px " + font_type;
    const textWidth = ctx.measureText(text).width;
    
    //Calculate ratio of text width to size of font used
    const ratio = textWidth/size;
	
	//set font to be actually used to write text on dynamic texture
    const font_size = Math.floor(DTWidth / (ratio * 1)); //size of multiplier (1) can be adjusted, increase for smaller text
    const font = font_size + "px " + font_type;
	
	//Draw text
    dynamicTexture.drawText(text, null, null, font, "#00ff00", "#a000000", true);

    //create material
    const mat = new StandardMaterial("mat", this.scene);
    mat.diffuseTexture = dynamicTexture;
    //apply material
    plane.material = mat;

    }

    createSkybox():void{
        //skybox
        const skybox = MeshBuilder.CreateBox("skybox", { size:1000.0 }, this.scene)
        const skyboxMaterial = new StandardMaterial("skibox", this.scene)
        skyboxMaterial.backFaceCulling = false
        skyboxMaterial.reflectionTexture = new CubeTexture("./textures/skybox/skybox", this.scene)
        skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE
        skyboxMaterial.diffuseColor = new Color3(0,0,0)
        skyboxMaterial.specularColor = new Color3(0,0,0)
        skybox.material = skyboxMaterial
    }

    createMusic():void{
        const music = new Sound("Background",'./music/birds.mp3', this.scene, null, {
            loop:true,
            autoplay:true
        })
    }

    CreateGroundMaterial():StandardMaterial{
        const groundMaterial = new StandardMaterial("groundMaterial", this.scene)
        const uvScale = 30
        const texturesArray: Texture[] = []

        const diffuseTexture = new Texture("./textures/ground/coast_diffuse.jpg", this.scene)
        groundMaterial.diffuseTexture = diffuseTexture
        texturesArray.push(diffuseTexture)

        //fa effetto brillante, fa schifo
        //const normalTexture = new Texture("./textures/ground/coast_normal.jpg", this.scene)
        //groundMaterial.bumpTexture = normalTexture
        //texturesArray.push(normalTexture)

        const aoTexture = new Texture("./textures/ground/coast_ao.jpg", this.scene)
        groundMaterial.ambientTexture = aoTexture
        texturesArray.push(aoTexture)

        texturesArray.forEach((tex)=>{
            tex.uScale = uvScale;
            tex.vScale = uvScale;
        })

        
        return groundMaterial
    } 



    async GetEcoScores(){
        const {data} = await axios.get<GetLeaderboardResponse>('https://ecoapi.namirial.com/get-leaderboard');
        return data.Response;
    }

    async CreateTrees(howMany=1):Promise<void>{
        const models = await SceneLoader.ImportMeshAsync("", "//www.babylonjs.com/assets/Tree/", "tree.babylon", this.scene)
        const meshes = models.meshes
        const theMesh:Mesh = meshes[0] as Mesh
        const theMeshMaterial:StandardMaterial = theMesh.material as StandardMaterial
        theMeshMaterial.opacityTexture = null
        theMesh.isVisible = false

        for (let i = -howMany/2; i < howMany; i++) {


            const actualX = i * 4
            const actualZ = 8

            const newInstance = theMesh.createInstance("tree-"+i);
            newInstance.scaling = new Vector3(20,20,20); //begli alberoni ciccioni!
            newInstance.position = new Vector3(actualX, 0, actualZ);
            //li rendo collidabili
            newInstance.checkCollisions = true
        }
    }

}

